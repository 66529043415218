import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Gulf of Mexico Energy Security Act (GOMESA)(Gulf of America) | How revenue works",
  "includeToc": true,
  "description": "The Gulf of Mexico Energy Security Act (GOMESA) (Gulf of America) of 2006 created a revenue-sharing model for oil- and gas-producing Gulf of America states. Under GOMESA, Alabama, Louisiana, Mississippi, and Texas receive a portion of the revenue from oil and gas produced in the Gulf. GOMESA also directs a portion of revenue to the Land and Water Conservation Fund.",
  "tags": ["How revenue works", "Gulf of America", "Federal laws", "Regulations", "GOMESA", "Land and Water Conservation Fund", "Revenue", "Disbursements", "Alabama", "Louisiana", "Mississippi", "Texas"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GlossaryTerm = makeShortcode("GlossaryTerm");
const Table = makeShortcode("Table");
const TableHead = makeShortcode("TableHead");
const TableRow = makeShortcode("TableRow");
const TableCell = makeShortcode("TableCell");
const TableBody = makeShortcode("TableBody");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/how-revenue-works/"
      }}>{`How revenue works /`}</a></p>

    <h1 {...{
      "id": "Gulf-of-Mexico-Energy-Security-Act-GOMESA-Gulf-of-America",
      "style": {
        "position": "relative"
      }
    }}>{`Gulf of Mexico Energy Security Act (GOMESA) (Gulf of America)`}</h1>
    <p>{`The Gulf of Mexico Energy Security Act (GOMESA) of 2006 created a revenue-sharing model for oil- and gas-producing Gulf of America states. Under GOMESA, Alabama, Louisiana, Mississippi, and Texas receive a portion of the revenue generated from oil and gas production offshore in the Gulf of America. GOMESA also directs a portion of revenue to the Land and Water Conservation Fund.`}</p>
    <h2 {...{
      "id": "Revenue-sharing",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue sharing`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.boem.gov/sites/default/files/oil-and-gas-energy-program/Energy-Economics/Econ/GOMESA.pdf"
      }}>{`Gulf of Mexico Energy Security Act of 2006 (PDF) `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{` created revenue-sharing provisions for four Gulf of America states and their `}<GlossaryTerm mdxType="GlossaryTerm">{`coastal political subdivisions`}</GlossaryTerm>{`:`}</p>
    <ul>
      <li parentName="ul">{`Alabama`}</li>
      <li parentName="ul">{`Louisiana`}</li>
      <li parentName="ul">{`Mississippi`}</li>
      <li parentName="ul">{`Texas`}</li>
    </ul>
    <h3 {...{
      "id": "GOMESA-disbursements",
      "style": {
        "position": "relative"
      }
    }}>{`GOMESA disbursements`}</h3>
    <p>{`The Office of Natural Resources Revenue disburses GOMESA revenue to state and local governments for each of the four GOMESA states. The numbers below show the disbursements to the states and their local governments. Disbursements are made the year following the year of receipt and are subject to sequestration.`}</p>
    <h4 {...{
      "id": "Alabama",
      "style": {
        "position": "relative"
      }
    }}>{`Alabama`}</h4>
    <Table mdxType="Table">
    <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Recipient</TableCell>
        <TableCell mdxType="TableCell">Fiscal Year 2024 Disbursement</TableCell>
    </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
        <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">State of Alabama</TableCell><TableCell mdxType="TableCell">$39,864,143</TableCell></TableRow>
        <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Baldwin County</TableCell><TableCell mdxType="TableCell">$4,671,961</TableCell></TableRow>
        <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Mobile County</TableCell><TableCell mdxType="TableCell">$5,294,074</TableCell></TableRow>
        <TableRow mdxType="TableRow"><TableCell mdxType="TableCell"><b>Alabama Total</b></TableCell><TableCell mdxType="TableCell"><b>$49,830,178</b></TableCell></TableRow>
        </TableBody>
    </Table>
 <Link href='/query-data/?dataType=Disbursements&period=Fiscal%20Year&fiscalYear=2009%2C2010%2C2011%2C2012%2C2013%2C2014%2C2015%2C2016%2C2017%2C2018%2C2019%2C2020%2C2021%2C2022%2C2023&groupBy=localRecipient&usStateName=Alabama&source=GOMESA%20offshore' linkType='FilterTable' mdxType="Link">View FY 2009 - FY 2023 Alabama GOMESA disbursements</Link>
    <h4 {...{
      "id": "Louisiana",
      "style": {
        "position": "relative"
      }
    }}>{`Louisiana`}</h4>
    <Table mdxType="Table">
    <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Recipient</TableCell>
        <TableCell mdxType="TableCell">Fiscal Year 2024 Disbursement</TableCell>
    </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">State of Louisiana</TableCell><TableCell mdxType="TableCell">$125,063,554</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Assumption Parish</TableCell><TableCell mdxType="TableCell">$1,025,576</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Calcasieu Parish</TableCell><TableCell mdxType="TableCell">$1,691,776</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Cameron Parish</TableCell><TableCell mdxType="TableCell">$2,095,496</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Iberia Parish</TableCell><TableCell mdxType="TableCell">$1,617,190</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Jefferson Parish</TableCell><TableCell mdxType="TableCell">$2,538,334</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Lafourche Parish</TableCell><TableCell mdxType="TableCell">$1,612,388</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Livingston Parish</TableCell><TableCell mdxType="TableCell">$1,325,122</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Orleans Parish</TableCell><TableCell mdxType="TableCell">$2,223,457</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Plaquemines Parish</TableCell><TableCell mdxType="TableCell">$3,055,059</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">St. Bernard Parish</TableCell><TableCell mdxType="TableCell">$1,493,149</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">St. Charles Parish</TableCell><TableCell mdxType="TableCell">$1,099,143</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">St. James Parish</TableCell><TableCell mdxType="TableCell">$957,875</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">St. John The Baptist Parish</TableCell><TableCell mdxType="TableCell">$1,025,907</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">St. Martin Parish</TableCell><TableCell mdxType="TableCell">$1,153,309</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">St. Mary Parish</TableCell><TableCell mdxType="TableCell">$1,341,026</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">St. Tammany Parish</TableCell><TableCell mdxType="TableCell">$1,770,847</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Tangipahoa Parish</TableCell><TableCell mdxType="TableCell">$1,270,079</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Terrebonne Parish</TableCell><TableCell mdxType="TableCell">$2,358,502</TableCell></TableRow>
          <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Vermilion Parish</TableCell><TableCell mdxType="TableCell">$1,611,655</TableCell></TableRow>
        <TableRow mdxType="TableRow"><TableCell mdxType="TableCell"><b>Louisiana Total</b></TableCell><TableCell mdxType="TableCell"><b>$156,329,443</b></TableCell></TableRow>
        </TableBody>
        </Table>
 <Link href='/query-data/?dataType=Disbursements&period=Fiscal%20Year&fiscalYear=2009%2C2010%2C2011%2C2012%2C2013%2C2014%2C2015%2C2016%2C2017%2C2018%2C2019%2C2020%2C2021%2C2022%2C2023&groupBy=localRecipient&usStateName=Louisiana&source=GOMESA%20offshore' linkType='FilterTable' mdxType="Link">View FY 2009 - FY 2023 Louisiana GOMESA disbursements</Link>
    <h4 {...{
      "id": "Mississippi",
      "style": {
        "position": "relative"
      }
    }}>{`Mississippi`}</h4>
    <Table mdxType="Table">
    <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Recipient</TableCell>
        <TableCell mdxType="TableCell">Fiscal Year 2024 Disbursement</TableCell>
    </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">State of Mississippi</TableCell><TableCell mdxType="TableCell">$41,532,091</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Hancock County</TableCell><TableCell mdxType="TableCell">$1,966,890</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Harrison County</TableCell><TableCell mdxType="TableCell">$4,088,104</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Jackson County</TableCell><TableCell mdxType="TableCell">$4,328,028</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell"><b>Mississippi Total</b></TableCell><TableCell mdxType="TableCell"><b>$51,915,113</b></TableCell></TableRow>
    </TableBody>
    </Table>
 <Link href='/query-data/?dataType=Disbursements&period=Fiscal%20Year&fiscalYear=2009%2C2010%2C2011%2C2012%2C2013%2C2014%2C2015%2C2016%2C2017%2C2018%2C2019%2C2020%2C2021%2C2022%2C2023&groupBy=localRecipient&usStateName=Mississippi&source=GOMESA%20offshore' linkType='FilterTable' mdxType="Link">View FY 2009 - FY 2023 Mississippi GOMESA disbursements</Link>
    <h4 {...{
      "id": "Texas",
      "style": {
        "position": "relative"
      }
    }}>{`Texas`}</h4>
    <Table mdxType="Table">
    <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Recipient</TableCell>
        <TableCell mdxType="TableCell">Fiscal Year 2024 Disbursement</TableCell>
    </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">State of Texas</TableCell><TableCell mdxType="TableCell">$76,440,213</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Aransas County</TableCell><TableCell mdxType="TableCell">$772,864</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Brazoria County</TableCell><TableCell mdxType="TableCell">$1,282,654</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Calhoun County</TableCell><TableCell mdxType="TableCell">$1,033,977</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Cameron County</TableCell><TableCell mdxType="TableCell">$1,088,047</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Chambers County</TableCell><TableCell mdxType="TableCell">$660,083</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Galveston County</TableCell><TableCell mdxType="TableCell">$1,682,546</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Harris County</TableCell><TableCell mdxType="TableCell">$3,785,015</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Jackson County</TableCell><TableCell mdxType="TableCell">$497,349</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Jefferson County</TableCell><TableCell mdxType="TableCell">$1,266,118</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Kenedy County</TableCell><TableCell mdxType="TableCell">$1,068,574</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Kleberg County</TableCell><TableCell mdxType="TableCell">$768,247</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Matagorda County</TableCell><TableCell mdxType="TableCell">$1,473,459</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Nueces County</TableCell><TableCell mdxType="TableCell">$999,510</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Orange County</TableCell><TableCell mdxType="TableCell">$627,741</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Refugio County</TableCell><TableCell mdxType="TableCell">$476,195</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">San Patricio County</TableCell><TableCell mdxType="TableCell">$498,580</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Victoria County</TableCell><TableCell mdxType="TableCell">$511,982</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell">Willacy County</TableCell><TableCell mdxType="TableCell"> $617,113</TableCell></TableRow>
    <TableRow mdxType="TableRow"><TableCell mdxType="TableCell"><b>Texas Total</b></TableCell><TableCell mdxType="TableCell"><b>$95,550,266</b></TableCell></TableRow>
        </TableBody>
    </Table>
 <Link href='/query-data/?dataType=Disbursements&period=Fiscal%20Year&fiscalYear=2009%2C2010%2C2011%2C2012%2C2013%2C2014%2C2015%2C2016%2C2017%2C2018%2C2019%2C2020%2C2021%2C2022%2C2023&groupBy=localRecipient&usStateName=Texas&source=GOMESA%20offshore' linkType='FilterTable' mdxType="Link">View FY 2009 - FY 2023 Texas GOMESA disbursements</Link>
    <h3 {...{
      "id": "Land-and-Water-Conservation-Fund",
      "style": {
        "position": "relative"
      }
    }}>{`Land and Water Conservation Fund`}</h3>
    <p>{`GOMESA also directs a portion of Gulf of America revenue to the `}<a parentName="p" {...{
        "href": "/how-revenue-works/lwcf/"
      }}>{`Land and Water Conservation Fund`}</a>{`. This fund supports preservation, development, and access to outdoor lands for public recreation.`}</p>
    <h3 {...{
      "id": "Purpose-of-funds",
      "style": {
        "position": "relative"
      }
    }}>{`Purpose of funds`}</h3>
    <p>{`GOMESA funds are to be used for coastal conservation, restoration, and hurricane protection.`}</p>
    <h2 {...{
      "id": "Phases",
      "style": {
        "position": "relative"
      }
    }}>{`Phases`}</h2>
    <p>{`There are two phases of GOMESA revenue sharing:`}</p>
    <br />
    <p><strong parentName="p">{`Phase I`}</strong>{`: Since 2007, 37.5% of all qualified Gulf of America revenues are shared among the four states and their coastal political subdivisions. Revenues are generated from leases in specific geographic areas defined in the act. Additionally, 12.5% of revenues are disbursed to the Land and Water Conservation Fund.`}</p>
    <br />
    <p><strong parentName="p">{`Phase II`}</strong>{`: The second phase of GOMESA revenue sharing started in fiscal year 2017. It expands the `}<a parentName="p" {...{
        "href": "https://www.boem.gov/sites/default/files/oil-and-gas-energy-program/Leasing/GOMESA-Map.pdf"
      }}>{`areas that qualify for revenue-sharing (PDF) `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{` under GOMESA.`}</p>
    <br />
    <p>{`Phase II also imposes revenue-sharing caps on states and the Land and Water Conservation Fund. Overall state revenue-sharing caps are:`}</p>
    <ul>
      <li parentName="ul">{`$375 million for fiscal years 2017–2019`}</li>
      <li parentName="ul">{`$487.5 million for fiscal years 2020 and 2021`}</li>
      <li parentName="ul">{`$375 million for fiscal years 2022–2055`}</li>
    </ul>
    <p>{`The cap is lifted beginning in fiscal year 2056.`}</p>
    <h2 {...{
      "id": "Expanded-leasing-area",
      "style": {
        "position": "relative"
      }
    }}>{`Expanded leasing area`}</h2>
    <p>{`GOMESA stipulated 8.3 million acres be offered for oil and gas leasing shortly after the enactment of the statute. This acreage is included in the Central Gulf of America Planning Area and the Eastern Gulf of America Planning Area.`}</p>
    <h2 {...{
      "id": "Extended-moratorium",
      "style": {
        "position": "relative"
      }
    }}>{`Extended moratorium`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.boem.gov/oil-gas-energy/leasing/areas-under-restriction"
      }}>{`GOMESA Moratorium `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{` covers a portion of the Central Gulf of America Planning Area and — until 2022 — most of the Eastern Gulf of America Planning Area.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      